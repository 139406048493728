import { Injectable } from "@angular/core";
import { UtilsService } from "./utils/utils.service";
import { HttpService } from "./utils/http.service";
import { CustomersService } from "./customers.service";
import { ContactsService } from "./contacts.service";
import { LookupService } from "./utils/lookup.service";
import { IDropdownModel, IJobModel, JobModel, IJobGridModel, IJobNoteModel, IContactModel, IEquipmentByTypeModel, IWIPModel, IWIPLaborDetailModel } from "@models";
import { IMobileJobsListSettingsModel } from "@models/jobs/mobile-jobs-list-settings.model";
import { GlobalsService } from "./utils/globals.service";
import * as moment from 'moment';

@Injectable()
export class JobsService {
	constructor(private httpService: HttpService, private lookupService: LookupService, private customersService: CustomersService, private contactsService: ContactsService) {
	}

	async getNewJobModel(): Promise<IJobModel> {
		try {
			let jobSeq = await this.httpService.get("/jobs/getNextJobSeq");
			var jobModel: IJobModel = new JobModel();
			jobModel.jobId = 0;
			jobModel.uuid = UtilsService.newGuid();
			jobModel.jobSeq = jobSeq;
			jobModel.jobStatusId = (await this.lookupService.getJobStatuses()).find(x => x.description === "Lead").jobStatusId;

			jobModel.estimatorUserId = null;
			jobModel.referralAccountId = null;
			jobModel.referralContactId = null;
			jobModel.referralUuid = null;
			jobModel.primaryCustomer = this.customersService.getNewCustomerModel();
			// Job requires a secondary email address
			jobModel.primaryCustomer.contact.secondaryEmailAddress = this.contactsService.getNewEmailAddressModel();
			jobModel.primaryCustomer.contact.secondaryEmailAddress.isSecondary = true;

			jobModel.billingIsSameAsPrimary = true;

			jobModel.isSelfPay = false;
			jobModel.insuranceAdjusterId = null;
			jobModel.insuranceAgentId = null;

			jobModel.active = true;

			return Promise.resolve(jobModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	formatJobName(jobModel: IJobModel, forceRefresh: boolean = false): string {
		if (jobModel.jobId > 0 && forceRefresh === false)
			return jobModel.jobName;

		let jobName = GlobalsService.companySettings.jobNameTemplate;

		let firstName = (jobModel.primaryCustomer?.contact?.firstName ?? "");
		if (firstName)
			firstName = firstName[0].toUpperCase() + firstName.substring(1);

		let lastName = (jobModel.primaryCustomer?.contact?.lastName ?? "")
		if (lastName)
			lastName = lastName[0].toUpperCase() + lastName.substring(1);

		let firstLast = "";
		if (firstName && lastName)
			firstLast = firstName + " " + lastName;
		else if (firstName)
			firstLast = firstName;
		else if (lastName)
			firstLast = lastName;

		let lastFirst = "";
		if (firstName && lastName)
			lastFirst = lastName + ", " + firstName;
		else if (firstName)
			lastFirst = firstName;
		else if (lastName)
			lastFirst = lastName;

		jobName = jobName.replace("{LAST_NAME}", firstName);
		jobName = jobName.replace("{FIRST_NAME}", lastName);
		jobName = jobName.replace("{FIRST_LAST_NAME}", firstLast);
		jobName = jobName.replace("{LAST_FIRST_NAME}", lastFirst);
		jobName = jobName.replace("{JOB_SEQ}", jobModel.jobSeq.toString());
		jobName = jobName.replace("{DAMAGE_TYPE}", jobModel.damageTypeName ?? "");
		if ((jobModel.damageTypeName ?? "").length >= 1)
			jobName = jobName.replace("{DAMAGE_TYPE_INITIAL}", (jobModel.damageTypeName.substring(0, 1)));
		else
			jobName = jobName.replace("{DAMAGE_TYPE_INITIAL}", "");

		let idx = 0;
		let maxLoop = 10;
		do {
			idx = jobName.indexOf("{Date:");
			if (idx >= 0) {
				jobName = jobName.substring(0, idx) + jobName.substring(idx + 6, jobName.length);
				const endIdx = jobName.indexOf("}");
				if (endIdx >= 0) {
					const dateStr = jobName.substring(idx, endIdx).trim();
					const dateFormatted = moment().format(dateStr);
					const jobNamePre = jobName.substring(0, idx);
					const jobNamePost = jobName.substring(endIdx + 1, jobName.length)
					jobName = jobNamePre + dateFormatted + jobNamePost;
				}
			}

			maxLoop--;
		} while (idx >= 0 && maxLoop > 0)
		
		return jobName;
	}

	formatQuickbooksName(jobModel: IJobModel): string {
		let quickbooksName = `${jobModel.jobSeq}`;
		if (jobModel.damageTypeName) {
			let damageTypeName = jobModel.damageTypeName;
			
			if (damageTypeName.length > 4)
				damageTypeName = damageTypeName.substring(0, 3);

			// CRSLC wants to keep the single letter qb name
			if (GlobalsService.companyInfo.companyId == 7)
				damageTypeName = damageTypeName.substring(0, 1);

			quickbooksName = `${quickbooksName}-${damageTypeName}`;
		}

		return quickbooksName;
	}

	async getJob(jobId: number): Promise<IJobModel> {
		return Promise.resolve(await this.httpService.get("/jobs/getJob", { jobId: jobId }));

	}

	async getJobForGrid(jobId: number): Promise<IJobGridModel> {
		return Promise.resolve(await this.httpService.get("/jobs/getJobForGrid", { jobId: jobId }));
	}

	async getJobsForInfiniteScroll(pageCount: number, searchString: string, mobileJobsListSettingsModel: IMobileJobsListSettingsModel): Promise<IJobGridModel[]> {
		// Object.assign will combine into a single object
		const params = Object.assign({}, {
			pageCount: pageCount,
			searchString: searchString
		}, mobileJobsListSettingsModel);

		return Promise.resolve(await this.httpService.get("/jobs/getJobsForInfiniteScroll", params));
	}

	async getAllJobsWithEquipmentForDropdown(): Promise<IDropdownModel[]> {
		return Promise.resolve(await this.httpService.get("/jobs/getAllJobsWithEquipmentForDropdown"));
	}

	async getOpenJobNamesForDropdown(): Promise<IDropdownModel[]> {
		return Promise.resolve(await this.httpService.get("/jobs/getOpenJobNamesForDropdown"));
	}

	async getOpenJobNamesByBillingIdForDropdown(customerId: number): Promise<IDropdownModel[]> {
		return Promise.resolve(await this.httpService.get(`/jobs/getOpenJobNamesByBillingIdForDropdown?customerId=${customerId}`));
	}

	async updateJob(jobModel: IJobModel): Promise<IJobModel> {
		if (jobModel.isDeskAdjuster) {
			jobModel.insuranceAdjusterId = null;
			jobModel.insuranceAdjusterContact = null;
			jobModel.insuranceAdjusterName = null
		}
		else {
			jobModel.deskAdjusterInfo = null;
		}

		let jobModelToUpdate = UtilsService.clone(jobModel);

		if (jobModel.jobId === 0) {
			let returnModel = await this.httpService.post("/jobs/addJob", jobModelToUpdate);
			return Promise.resolve(returnModel);
		}
		else {
			return Promise.resolve(await this.httpService.post("/jobs/updateJob", jobModelToUpdate));
		}
	}

	async deleteJob(jobId: number): Promise<boolean> {
		return Promise.resolve(await this.httpService.delete("/jobs/deleteJob", { jobId: jobId }));
	}

	async updateJobStatus(jobId: number, jobStatusId: number): Promise<IJobModel> {
		let params = {
			jobId: jobId,
			jobStatusId: jobStatusId
		}

		return Promise.resolve(await this.httpService.post("/jobs/updateJobStatus", params));
	}

	async updateJobCustomStatus(jobId: number, jobCustomStatusId: number): Promise<IJobModel> {
		let params = {
			jobId: jobId,
			jobCustomStatusId: jobCustomStatusId
		}

		return Promise.resolve(await this.httpService.post("/jobs/updateJobCustomStatus", params));
	}

	async updateNotAcceptedReason(jobId: number, notAcceptedReasonId: number): Promise<IJobModel> {
		let params = {
			jobId: jobId,
			notAcceptedReasonId: notAcceptedReasonId
		}

		return Promise.resolve(await this.httpService.post("/jobs/updateNotAcceptedReason", params));
	}

	async updateJobDamageType(jobId: number, jobDamageTypeId: number): Promise<IJobModel> {
		let params = {
			jobId: jobId,
			jobDamageTypeId: jobDamageTypeId
		}

		return Promise.resolve(await this.httpService.post("/jobs/updateJobDamageType", params));
	}

	async updateScratchPad(jobId: number, scratchPad: string): Promise<IJobModel> {
		let params = {
			jobId: jobId,
			scratchPad: scratchPad
		}

		return Promise.resolve(await this.httpService.post("/jobs/updateScratchPad", params));
	}

	async updateDeductible(jobId: number, deductibleAmount: number): Promise<IJobModel> {
		let params = {
			jobId: jobId,
			deductibleAmount: deductibleAmount
		}

		return Promise.resolve(await this.httpService.post("/jobs/updateDeductible", params));
	}

	async resetActivityDays(jobId: number): Promise<IJobModel> {
		let params = {
			jobId: jobId
		}

		return Promise.resolve(await this.httpService.post("/jobs/resetActivityDays", params));
	}

	async logPhoneCall(jobId: number, phoneNumber: string) {
		const logText = `Made phone call to ${phoneNumber}`;

		this.httpService.post(`/jobs/logPhoneCall?jobId=${jobId}`, logText);
	}

	async addEquipmentToJob(jobId: number, equipmentId: number, mitigationRoomId?: number): Promise<boolean> {
		let params = {
			jobId: jobId,
			equipmentId: equipmentId,
			mitigationRoomId: mitigationRoomId
		}

		return Promise.resolve(await this.httpService.post("/jobs/addEquipmentToJob", params));
	}

	async removeEquipmentFromJob(jobId: number, equipmentId: number): Promise<boolean> {
		let params = {
			jobId: jobId,
			equipmentId: equipmentId
		}

		return Promise.resolve(await this.httpService.post("/jobs/removeEquipmentFromJob", params));
	}

	async removeAllEquipmentFromJob(jobId: number): Promise<boolean> {
		let params = {
			jobId: jobId
		}

		return Promise.resolve(await this.httpService.post("/jobs/removeAllEquipmentFromJob", params));
	}

	async removeAllEquipmentFromJobByEquipmentType(jobId: number, equipmentTypeId: number): Promise<boolean> {
		let params = {
			jobId: jobId,
			equipmentTypeId: equipmentTypeId
		}

		return Promise.resolve(await this.httpService.post("/jobs/removeAllEquipmentFromJobByEquipmentType", params));
	}

	async getJobUserNotes(jobId: number, count: number): Promise<IJobNoteModel[]> {
		let params = {
			jobId: jobId,
			count: count
		}
		return Promise.resolve(await this.httpService.get("/jobs/getJobUserNotes", params));
	}

	async getJobSystemNotes(jobId: number, count: number): Promise<IJobNoteModel[]> {
		let params = {
			jobId: jobId,
			count: count
		}
		return Promise.resolve(await this.httpService.get("/jobs/getJobSystemNotes", params));
	}

	async createEmailSentNote(referenceUuid: string, jobId: number): Promise<boolean> {
		return Promise.resolve(await this.httpService.post("/jobs/createEmailSentNote", { referenceUuid: referenceUuid, jobId: jobId }));
	}

	async addContactToJob(jobId: number, contactId: number): Promise<IContactModel> {
		return Promise.resolve(await this.httpService.post("/jobs/addContactToJob", { jobId: jobId, contactId: contactId }));
	}

	async removeContactFromJob(jobId: number, contactId: number): Promise<IContactModel> {
		return Promise.resolve(await this.httpService.post("/jobs/removeContactFromJob", { jobId: jobId, contactId: contactId }));
	}

	async pinJob(jobId: number): Promise<boolean> {
		return Promise.resolve(await this.httpService.post("/jobs/pinJob", { jobId: jobId }));
	}

	async unpinJob(jobId: number): Promise<boolean> {
		return Promise.resolve(await this.httpService.post("/jobs/unpinJob", { jobId: jobId }));
	}

	async getJobWIP(jobId: number): Promise<IWIPModel> {
		return Promise.resolve(await this.httpService.get("/jobs/getJobWIP", { jobId: jobId }));
	}

	async getJobWIPLaborDetails(jobId: number): Promise<IWIPLaborDetailModel[]> {
		return Promise.resolve(await this.httpService.get("/jobs/getJobWIPLaborDetails", { jobId: jobId }));
	}

	async syncToQuickbooks(jobId: number): Promise<IJobGridModel> {
		return Promise.resolve(await this.httpService.post("/jobs/syncToQuickbooks", jobId));
	}

	async unsyncFromQuickbooks(jobId: number): Promise<IJobGridModel> {
		return Promise.resolve(await this.httpService.post("/jobs/unsyncFromQuickbooks", jobId));
	}

	async generateInvoicePDF(jobId: number): Promise<string> {
		const response = await this.httpService.get("/jobs/generateInvoicePDF", { jobId });
		return response.url;
	}


	async sendInTransitText(jobId: number, appointmentId: number, travelDuration: number): Promise<void> {
		const params = {
			jobId,
			appointmentId,
			travelDuration
		}

		return this.httpService.post('/textChat/sendInTransitText', params);
	}
}

