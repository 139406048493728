import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { IDropdownModel, DropdownModel, IClassModel, ICOGSItemModel, IExpenseItemModel, IInsuranceCarrierModel, IInsuranceAdjusterModel, IInsuranceAgentModel, IItemModel, IDamageTypeModel, IEmailerAddressModel, IEmployeeModel, IEquipmentStatusModel, IEquipmentTypeModel, IEstimatorModel, IJobStatusModel, IPayPeriodModel, ITeamModel, IAppointmentReminderDefaultModel, EstimatorModel, IPayableCreditCardModel, IReferralModel, IBusinessDevelopmentContactRoleModel, IBusinessDevelopmentAccountTypeModel, IBusinessDevelopmentAccountModel, IPayPeriodDefinitionModel, IJobCustomStatusModel, INotAcceptedReasonModel } from "@models";
import { sortBy } from "sort-by-typescript";
import { FunctionLockService } from "./function-lock.service";
import { UtilsService } from "./utils.service";

enum LookupTypes {
	AppointmentReminderDefaults = 'AppointmentReminderDefaults',
	BusinessDevelopmentAccounts = 'BusinessDevelopmentAccounts',
	BusinessDevelopmentAccountTypes = 'BusinessDevelopmentAccountTypes',
	BusinessDevelopmentContactRoles = 'BusinessDevelopmentContactRoles',
	COGSItems = 'COGSItems',
	Classes = 'Classes',
	DamageTypes = 'DamageTypes',
	EmailerAddresses = 'EmailerAddresses',
	Employees = 'Employees',
	EquipmentStatuses = 'EquipmentStatuses',
	EquipmentTypes = 'EquipmentTypes',
	ExpenseItems = 'ExpenseItems',
	InsuranceAdjusters = 'InsuranceAdjusters',
	InsuranceAgents = 'InsuranceAgents',
	InsuranceCarriers = 'InsuranceCarriers',
	Items = 'Items',
	JobStatuses = 'JobStatuses',
	JobCustomStatuses = 'JobCustomStatuses',
	NotAcceptedReasons = 'NotAcceptedReasons',
	PayableCreditCards = 'PayableCreditCards',
	PaymentTypes = 'PaymentTypes',
	PayPeriodDefinitions = 'PayPeriodDefinitions',
	PayPeriods = 'PayPeriods',
	Referrals = 'Referrals',
	Teams = 'Teams'
};

class ReloadLookup {
	constructor(lookupType: string, reloadPromise: any) {
		this.lookupType = lookupType;
		this.reloadPromse = reloadPromise;
	}

	lookupType: string;
	reloadPromse: any;
}

@Injectable()
export class LookupService {
	private static lookupTypes: any = {};

	constructor(private httpService: HttpService,
		private functionLockService: FunctionLockService) { }

	private async refreshLookup<T>(lookupType: LookupTypes): Promise<T> {
		try {
			localStorage.removeItem(`LOOKUP_${lookupType}`);

			let url = `/lookups/get${lookupType}`
			LookupService.lookupTypes[lookupType] = await this.httpService.get(url);
			localStorage.setItem(`LOOKUP_${lookupType}`, JSON.stringify(LookupService.lookupTypes[lookupType]));
			return Promise.resolve(LookupService.lookupTypes[lookupType]);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	private async updateLookup<T>(lookupType: LookupTypes, model: T): Promise<T> {
		try {
			let url = `/lookups/update${LookupTypes[lookupType]}`;
			return Promise.resolve(await this.httpService.post(url, model));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	private async getLookup<T>(lookupType: LookupTypes, idFieldName: string, id?: number, includeInactive?: boolean): Promise<T[]> {
		return new Promise<T[]>(async (resolve) => {
			try {
				await this.functionLockService.lock(lookupType);

				if (!LookupService.lookupTypes[lookupType]) {
					const lookupStr = localStorage.getItem(`LOOKUP_${lookupType}`);
					if (lookupStr) {
						try {
							console.info(`localStorage ${lookupType}`);
							LookupService.lookupTypes[lookupType] = JSON.parse(lookupStr);
						}
						catch {
							console.info(`localStorage error ${lookupType}`);
						}
					}

					// If we didn't find it in localStorage or we got an error converting, load it from the server
					if (!LookupService.lookupTypes[lookupType]) {
						console.info(`Loading ${lookupType}`);
						const url = `/lookups/get${lookupType}`;
						LookupService.lookupTypes[lookupType] = await this.httpService.get(url);
						localStorage.setItem(`LOOKUP_${lookupType}`, JSON.stringify(LookupService.lookupTypes[lookupType]));
					}
				}

				let returnModel = UtilsService.clone((<T[]>LookupService.lookupTypes[lookupType]));
				id = id || 0;

				if (!includeInactive)
					returnModel = returnModel.filter(c => (c["active"] === undefined || c["active"] === true) && (c["isDeleted"] === undefined || c["isDeleted"] === false));
				if (id && returnModel.findIndex(c => c[idFieldName] === id) < 0) {
					let deletedModel = (<T[]>LookupService.lookupTypes[lookupType]).find(c => c[idFieldName] === id);
					returnModel.unshift(deletedModel);
				}

				resolve(returnModel || []);
			}
			finally {
				this.functionLockService.release(lookupType);
			}
		});


	}

	async clearLocalstorage() {
		console.info("Clearing lookup localStorage");

		const reloadLookups: ReloadLookup[] = [];

		// Quickly clear everything out of localStorage
		for (let lookupType in LookupTypes)
			localStorage.removeItem(`LOOKUP_${lookupType}`);

		// Reload anything that was previously loaded
		for (let lookupType in LookupTypes) {
			// Javascript is weird and will return 0-23 as well as the name of the enum
			// Only check if it's not a number
			if (isNaN(Number(lookupType))) {
				if (LookupService.lookupTypes[lookupType]) {
					await this.functionLockService.lock(lookupType);
					console.info(`Reloading ${lookupType}`);
					const url = `/lookups/get${lookupType}`;
					reloadLookups.push(new ReloadLookup(lookupType, this.httpService.get(url)));
				}
			}
		}

		if (reloadLookups.length > 0) {
			const allPromises = reloadLookups.map(x => x.reloadPromse);
			const results = await Promise.all(allPromises);

			for (let i = 0; i < results.length; i++) {
				const lookupType = reloadLookups[i].lookupType;
				LookupService.lookupTypes[lookupType] = results[i];
				localStorage.setItem(`LOOKUP_${lookupType}`, JSON.stringify(LookupService.lookupTypes[lookupType]));
				this.functionLockService.release(lookupType);
			}
		}

		console.info("Done clearing lookup localStorage");
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Appointment Reminder Defaults
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshAppointmentReminderDefaults(): Promise<IAppointmentReminderDefaultModel[]> {
		return this.refreshLookup<IAppointmentReminderDefaultModel[]>(LookupTypes.AppointmentReminderDefaults);
	}

	async getAppointmentReminderDefaults(): Promise<IAppointmentReminderDefaultModel[]> {
		return this.getLookup<IAppointmentReminderDefaultModel>(LookupTypes.AppointmentReminderDefaults, "appointmentReminderDefaultId");
	}

	async updateAppointmentReminderDefaults(appointmentReminderDefaults: IAppointmentReminderDefaultModel[]): Promise<IAppointmentReminderDefaultModel[]> {
		return new Promise<IAppointmentReminderDefaultModel[]>(async (resolve, reject) => {
			try {
				LookupService.lookupTypes[LookupTypes.AppointmentReminderDefaults] = await this.httpService.post("/lookups/updateAppointmentReminderDefaults", appointmentReminderDefaults);
				resolve(LookupService.lookupTypes[LookupTypes.AppointmentReminderDefaults]);
			}
			catch (error) {
				console.error(error);
				return reject(error);
			}
		});
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Business Development Accounts
	////////////////////////////////////////////////////////////////////////////////////////////////
	async getBusinessDevelopmentAccounts(): Promise<IBusinessDevelopmentAccountModel[]> {
		return this.getLookup<IBusinessDevelopmentAccountModel>(LookupTypes.BusinessDevelopmentAccounts, "businessDevelopmentAccountId");
	}

	async getBusinessDevelopmentAccountsForDropdown(): Promise<IDropdownModel[]> {
		const businessDevelopmentAccounts = await this.getBusinessDevelopmentAccounts();
		const dropdowns = businessDevelopmentAccounts.map(x => new DropdownModel(x.businessDevelopmentAccountId, x.contact.displayName));
		dropdowns.sort(sortBy("text^"));
		return dropdowns;
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Business Development Account Types
	////////////////////////////////////////////////////////////////////////////////////////////////
	async getBusinessDevelopmentAccountTypes(): Promise<IBusinessDevelopmentAccountTypeModel[]> {
		return this.getLookup<IBusinessDevelopmentAccountTypeModel>(LookupTypes.BusinessDevelopmentAccountTypes, "businessDevelopmentAccountTypeId");
	}

	async getBusinessDevelopmentAccountTypesForDropdown(): Promise<IDropdownModel[]> {
		return (await this.getLookup<IBusinessDevelopmentAccountTypeModel>(LookupTypes.BusinessDevelopmentAccountTypes, "businessDevelopmentAccountTypeId"))
			.map(x => new DropdownModel(x.businessDevelopmentAccountTypeId, x.description));
	}

	async updateBusinessDevelopmentAccountTypes(businessDevelopmentAccountTypes: IBusinessDevelopmentAccountTypeModel[]): Promise<IBusinessDevelopmentAccountTypeModel[]> {
		return new Promise<IBusinessDevelopmentAccountTypeModel[]>(async (resolve, reject) => {
			try {
				LookupService.lookupTypes[LookupTypes.BusinessDevelopmentAccountTypes] = await this.httpService.post("/lookups/updateBusinessDevelopmentAccountTypes", businessDevelopmentAccountTypes);
				resolve(LookupService.lookupTypes[LookupTypes.BusinessDevelopmentAccountTypes]);
			}
			catch (error) {
				console.error(error);
				return reject(error);
			}
		});
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Business Development Contact Roles
	////////////////////////////////////////////////////////////////////////////////////////////////
	async getBusinessDevelopmentContactRoles(): Promise<IBusinessDevelopmentContactRoleModel[]> {
		return this.getLookup<IBusinessDevelopmentContactRoleModel>(LookupTypes.BusinessDevelopmentContactRoles, "businessDevelopmentContactRoleId");
	}

	async getBusinessDevelopmentContactRolesForDropdown(): Promise<IDropdownModel[]> {
		return (await this.getLookup<IBusinessDevelopmentContactRoleModel>(LookupTypes.BusinessDevelopmentContactRoles, "businessDevelopmentContactRoleId"))
			.map(x => new DropdownModel(x.businessDevelopmentContactRoleId, x.roleName));
	}

	async updateBusinessDevelopmentContactRoles(businessDevelopmentContactRoles: IBusinessDevelopmentContactRoleModel[]): Promise<IBusinessDevelopmentContactRoleModel[]> {
		return new Promise<IBusinessDevelopmentContactRoleModel[]>(async (resolve, reject) => {
			try {
				LookupService.lookupTypes[LookupTypes.BusinessDevelopmentContactRoles] = await this.httpService.post("/lookups/updateBusinessDevelopmentContactRoles", businessDevelopmentContactRoles);
				resolve(LookupService.lookupTypes[LookupTypes.BusinessDevelopmentContactRoles]);
			}
			catch (error) {
				console.error(error);
				return reject(error);
			}
		});
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Business Accounts
	////////////////////////////////////////////////////////////////////////////////////////////////
	async refreshBusinessAccounts(): Promise<void> {
		await this.refreshInsuranceAdjusters();
		await this.refreshInsuranceAgents();
		await this.refreshInsuranceCarriers();

		return null;
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// COGS Items
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshCOGSItems(): Promise<ICOGSItemModel[]> {
		return this.refreshLookup<ICOGSItemModel[]>(LookupTypes.COGSItems);
	}

	async getCOGSItems(cogsItemId?: number, includeInactive?: boolean): Promise<ICOGSItemModel[]> {
		return this.getLookup<ICOGSItemModel>(LookupTypes.COGSItems, "cogsItemId", cogsItemId, includeInactive);
	}

	async getCOGSItemsForDropdown(cogsItemId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<ICOGSItemModel>(LookupTypes.COGSItems, "cogsItemId", cogsItemId))
			.map(x => new DropdownModel(x.cogsItemId, x.itemCode));
	}

	async updateCOGSItem(cogsItemModel: ICOGSItemModel): Promise<ICOGSItemModel> {
		try {
			let returnModel = await this.updateLookup(LookupTypes.COGSItems, cogsItemModel);
			await this.refreshCOGSItems();
			return Promise.resolve(returnModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}

	}


	////////////////////////////////////////////////////////////////////////////////////////////////
	// Classes
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshClasses(): Promise<IClassModel[]> {
		return this.refreshLookup<IClassModel[]>(LookupTypes.Classes);
	}

	async getClasses(classId?: number, includeInactive?: boolean): Promise<IClassModel[]> {
		return this.getLookup<IClassModel>(LookupTypes.Classes, "classId", classId, includeInactive);
	}

	async getClassesForDropdown(classId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IClassModel>(LookupTypes.Classes, "classId", classId))
			.map(x => new DropdownModel(x.classId, x.className));
	}

	async updateClass(classModel: IClassModel): Promise<IClassModel> {
		try {
			let returnModel = await this.updateLookup(LookupTypes.Classes, classModel);
			await this.refreshClasses();
			return Promise.resolve(returnModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}

	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Damage Types
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshDamageTypes(): Promise<IDamageTypeModel[]> {
		return this.refreshLookup<IDamageTypeModel[]>(LookupTypes.DamageTypes);
	}

	async getDamageTypes(damageTypeId?: number, includeInactive?: boolean): Promise<IDamageTypeModel[]> {
		return this.getLookup<IDamageTypeModel>(LookupTypes.DamageTypes, "damageTypeId", damageTypeId, includeInactive);
	}

	async getDamageTypesForDropdown(damageTypeId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IDamageTypeModel>(LookupTypes.DamageTypes, "damageTypeId", damageTypeId))
			.map(x => new DropdownModel(x.damageTypeId, x.description));
	}

	async updateDamageType(damageTypeModel: IDamageTypeModel): Promise<IDamageTypeModel> {
		try {
			let returnModel = await this.updateLookup(LookupTypes.DamageTypes, damageTypeModel);
			await this.refreshDamageTypes();
			return Promise.resolve(returnModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}

	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Email Address Strings
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshEmailerAddresses(): Promise<IEmailerAddressModel[]> {
		return this.refreshLookup<IEmailerAddressModel[]>(LookupTypes.EmailerAddresses);
	}

	getEmailerAddresses(): Promise<IEmailerAddressModel[]> {
		return this.getLookup(LookupTypes.EmailerAddresses, null);
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Employees
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshEmployees(): Promise<IEmployeeModel[]> {
		return this.refreshLookup<IEmployeeModel[]>(LookupTypes.Employees);
	}

	async getEmployees(userId?: number, includeInactive?: boolean): Promise<IEmployeeModel[]> {
		return this.getLookup<IEmployeeModel>(LookupTypes.Employees, "userId", userId, includeInactive);
	}

	async getEmployeesForDropdown(userId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IEmployeeModel>(LookupTypes.Employees, "userId", userId))
			.map(x => new DropdownModel(x.userId, x.fullName));
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Equipment Statuses
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshEquipmentStatuses(): Promise<IEquipmentStatusModel[]> {
		return this.refreshLookup<IEquipmentStatusModel[]>(LookupTypes.EquipmentStatuses);
	}

	async getEquipmentStatuses(equipmentStatusId?: number, includeInactive?: boolean): Promise<IEquipmentStatusModel[]> {
		return this.getLookup<IEquipmentStatusModel>(LookupTypes.EquipmentStatuses, "equipmentStatusId", equipmentStatusId, includeInactive);
	}

	async getEquipmentStatusesForDropdown(equipmentStatusId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IEquipmentStatusModel>(LookupTypes.EquipmentStatuses, "equipmentStatusId", equipmentStatusId))
			.map(x => new DropdownModel(x.equipmentStatusId, x.status));
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Equipment Types
	////////////////////////////////////////////////////////////////////////////////////////////////

	refreshEquipmentTypes(): Promise<IEquipmentTypeModel[]> {
		return this.refreshLookup<IEquipmentTypeModel[]>(LookupTypes.EquipmentTypes);
	}

	async getEquipmentTypes(equipmentTypeId?: number, includeInactive?: boolean): Promise<IEquipmentTypeModel[]> {
		return this.getLookup<IEquipmentTypeModel>(LookupTypes.EquipmentTypes, "equipmentTypeId", equipmentTypeId, includeInactive);
	}

	async getEquipmentTypesForDropdown(equipmentTypeId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IEquipmentTypeModel>(LookupTypes.EquipmentTypes, "equipmentTypeId", equipmentTypeId))
			.map(x => new DropdownModel(x.equipmentTypeId, x.description));
	}

	updateEquipmentType(equipmentTypeModel: IEquipmentTypeModel): Promise<IEquipmentTypeModel> {
		return this.updateLookup(LookupTypes.EquipmentTypes, equipmentTypeModel);
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Estimators
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshEstimators(): Promise<IEstimatorModel[]> {
		return this.refreshLookup<IEmployeeModel[]>(LookupTypes.Employees);
	}

	async getEstimators(userId?: number, includeInactive?: boolean): Promise<IEstimatorModel[]> {
		const estimators = (await this.getLookup<IEmployeeModel>(LookupTypes.Employees, "userId", userId, false))
			.filter(x => x.isEstimator === true)
			.map(x => {
				const estimator = new EstimatorModel();
				estimator.userId = x.userId;
				estimator.fullName = x.fullName;
				estimator.active = x.active;

				return estimator;
			});

		// It's possible this estimator is no longer an estimator user type.  If not, look in the employees table
		if (userId && !estimators.find(x => x.userId === userId)) {
			const employees = await this.getLookup<IEmployeeModel>(LookupTypes.Employees, "userId", userId, true);
			const estimatorEmployee = employees.find(x => x.userId === userId);

			if (estimatorEmployee) {
				const estimator = new EstimatorModel();
				estimator.userId = estimatorEmployee.userId;
				estimator.fullName = estimatorEmployee.fullName;
				estimator.active = estimatorEmployee.active;
				estimators.push(estimator);
			}

		}

		estimators.sort(sortBy("fullName^"));

		return estimators;
	}

	async getEstimatorsForDropdown(userId?: number): Promise<IDropdownModel[]> {
		const estimators = (await this.getEstimators(userId, true))
			.filter(x => x.active === true || x.userId === userId)
			.map(x => new DropdownModel(x.userId, x.fullName))

		return estimators;
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Expense Items
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshExpenseItems(): Promise<IExpenseItemModel[]> {
		return this.refreshLookup<IExpenseItemModel[]>(LookupTypes.ExpenseItems);
	}

	async getExpenseItems(expenseItemId?: number, includeInactive?: boolean): Promise<IExpenseItemModel[]> {
		return this.getLookup<IExpenseItemModel>(LookupTypes.ExpenseItems, "expenseItemId", expenseItemId, includeInactive);
	}

	async getExpenseItemsForDropdown(expenseItemId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IExpenseItemModel>(LookupTypes.ExpenseItems, "expenseItemId", expenseItemId))
			.map(x => new DropdownModel(x.expenseItemId, x.itemCode));
	}

	async updateExpenseItem(expenseItemModel: IExpenseItemModel): Promise<IExpenseItemModel> {
		try {
			let returnModel = await this.updateLookup(LookupTypes.ExpenseItems, expenseItemModel);
			await this.refreshExpenseItems();
			return Promise.resolve(returnModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}

	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Insurance Adjusters
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshInsuranceAdjusters(): Promise<IInsuranceAdjusterModel[]> {
		return this.refreshLookup<IInsuranceAdjusterModel[]>(LookupTypes.InsuranceAdjusters);
	}

	async getInsuranceAdjusters(businessDevelopmentContactId?: number, includeInactive?: boolean): Promise<IInsuranceAdjusterModel[]> {
		return this.getLookup<IInsuranceAdjusterModel>(LookupTypes.InsuranceAdjusters, "businessDevelopmentContactId", businessDevelopmentContactId, includeInactive);
	}

	async getInsuranceAdjustersForDropdown(businessDevelopmentContactId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IInsuranceAdjusterModel>(LookupTypes.InsuranceAdjusters, "businessDevelopmentContactId", businessDevelopmentContactId))
			.map(x => new DropdownModel(x.businessDevelopmentContactId, x.displayName));
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Insurance Agents
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshInsuranceAgents(): Promise<IInsuranceAgentModel[]> {
		return this.refreshLookup<IInsuranceAgentModel[]>(LookupTypes.InsuranceAgents);
	}

	async getInsuranceAgents(businessDevelopmentContactId?: number, includeInactive?: boolean): Promise<IInsuranceAgentModel[]> {
		return this.getLookup<IInsuranceAgentModel>(LookupTypes.InsuranceAgents, "businessDevelopmentContactId", businessDevelopmentContactId, includeInactive);
	}

	async getInsuranceAgentsForDropdown(businessDevelopmentContactId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IInsuranceAgentModel>(LookupTypes.InsuranceAgents, "businessDevelopmentContactId", businessDevelopmentContactId))
			.map(x => new DropdownModel(x.businessDevelopmentContactId, x.displayName));
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Insurance Carriers
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshInsuranceCarriers(): Promise<IInsuranceCarrierModel[]> {
		return this.refreshLookup<IInsuranceCarrierModel[]>(LookupTypes.InsuranceCarriers);
	}

	async getInsuranceCarriers(businessDevelopmentAccountId?: number, includeInactive?: boolean): Promise<IInsuranceCarrierModel[]> {
		return this.getLookup<IInsuranceCarrierModel>(LookupTypes.InsuranceCarriers, "businessDevelopmentAccountId", businessDevelopmentAccountId, includeInactive);
	}

	async getInsuranceCarriersForDropdown(businessDevelopmentAccountId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IInsuranceCarrierModel>(LookupTypes.InsuranceCarriers, "businessDevelopmentAccountId", businessDevelopmentAccountId))
			.map(x => new DropdownModel(x.businessDevelopmentAccountId, x.displayName));
	}


	////////////////////////////////////////////////////////////////////////////////////////////////
	// Items
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshItems(): Promise<IItemModel[]> {
		return this.refreshLookup<IItemModel[]>(LookupTypes.Items);
	}

	async getItems(itemId?: number, includeInactive?: boolean): Promise<IItemModel[]> {
		return this.getLookup<IItemModel>(LookupTypes.Items, "itemId", itemId, includeInactive);
	}

	async getItemsForDropdown(itemId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IItemModel>(LookupTypes.Items, "itemId", itemId))
			.map(x => new DropdownModel(x.itemId, x.itemCode));
	}

	async updateItem(itemModel: IItemModel): Promise<IItemModel> {
		try {
			let returnModel = await this.updateLookup(LookupTypes.Items, itemModel);
			await this.refreshItems();
			return Promise.resolve(returnModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}

	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Job Statuses
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshJobStatuses(): Promise<IJobStatusModel[]> {
		return this.refreshLookup<IJobStatusModel[]>(LookupTypes.JobStatuses);
	}

	async getJobStatuses(jobStatusId?: number, includeInactive?: boolean): Promise<IJobStatusModel[]> {
		return this.getLookup<IJobStatusModel>(LookupTypes.JobStatuses, "jobStatusId", jobStatusId, includeInactive);
	}

	async getJobStatusesForDropdown(jobStatusId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IJobStatusModel>(LookupTypes.JobStatuses, "jobStatusId", jobStatusId))
			.map(x => new DropdownModel(x.jobStatusId, x.description));
	}

	async updateJobStatus(jobStatusModel: IJobStatusModel): Promise<IJobStatusModel> {
		let returnModel = await this.updateLookup(LookupTypes.JobStatuses, jobStatusModel);
		await this.refreshJobStatuses();
		return returnModel;
	}


	////////////////////////////////////////////////////////////////////////////////////////////////
	// Job Custom Statuses
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshJobCustomStatuses(): Promise<IJobCustomStatusModel[]> {
		return this.refreshLookup<IJobCustomStatusModel[]>(LookupTypes.JobCustomStatuses);
	}

	async getJobCustomStatuses(jobCustomStatusId?: number, includeInactive?: boolean): Promise<IJobCustomStatusModel[]> {
		return this.getLookup<IJobCustomStatusModel>(LookupTypes.JobCustomStatuses, "jobCustomStatusId", jobCustomStatusId, includeInactive);
	}

	async getJobCustomStatusesForDropdown(jobCustomStatusId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IJobCustomStatusModel>(LookupTypes.JobCustomStatuses, "jobCustomStatusId", jobCustomStatusId))
			.map(x => new DropdownModel(x.jobCustomStatusId, x.status));
	}

	async updateJobCustomStatus(jobCustomStatusModel: IJobCustomStatusModel): Promise<IJobCustomStatusModel> {
		let returnModel = await this.updateLookup(LookupTypes.JobCustomStatuses, jobCustomStatusModel);
		await this.refreshJobCustomStatuses();
		return returnModel;
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Not Accepted Reasons
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshNotAcceptedReasons(): Promise<INotAcceptedReasonModel[]> {
		return this.refreshLookup<INotAcceptedReasonModel[]>(LookupTypes.NotAcceptedReasons);
	}

	async getNotAcceptedReasons(notAcceptedReasonId?: number, includeInactive?: boolean): Promise<INotAcceptedReasonModel[]> {
		return this.getLookup<INotAcceptedReasonModel>(LookupTypes.NotAcceptedReasons, "notAcceptedReasonId", notAcceptedReasonId, includeInactive);
	}

	async getNotAcceptedReasonsForDropdown(notAcceptedReasonId?: number): Promise<IDropdownModel[]> {
		console.log(await this.getLookup<INotAcceptedReasonModel>(LookupTypes.NotAcceptedReasons, "notAcceptedReasonId", notAcceptedReasonId))
		return (await this.getLookup<INotAcceptedReasonModel>(LookupTypes.NotAcceptedReasons, "notAcceptedReasonId", notAcceptedReasonId))
			.map(x => new DropdownModel(x.notAcceptedReasonId, x.reason));
	}

	async updateNotAcceptedReason(notAcceptedReasonModel: INotAcceptedReasonModel): Promise<INotAcceptedReasonModel> {
		let returnModel = await this.updateLookup(LookupTypes.NotAcceptedReasons, notAcceptedReasonModel);
		await this.refreshNotAcceptedReasons();
		return returnModel;
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Payable Credit Cards
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshPayableCreditCards(): Promise<IPayableCreditCardModel[]> {
		return this.refreshLookup<IPayableCreditCardModel[]>(LookupTypes.PayableCreditCards);
	}

	async getPayableCreditCards(payableCreditCardId?: number, includeInactive?: boolean): Promise<IPayableCreditCardModel[]> {
		return this.getLookup<IPayableCreditCardModel>(LookupTypes.PayableCreditCards, "payableCreditCardId", payableCreditCardId, includeInactive);
	}

	async getPayableCreditCardsForDropdown(payableCreditCardId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<IPayableCreditCardModel>(LookupTypes.PayableCreditCards, "payableCreditCardId", payableCreditCardId))
			.map(x => <any>{ id: x.description, text: x.description });
	}

	async updatePayableCreditCard(payableCreditCardModel: IPayableCreditCardModel): Promise<IPayableCreditCardModel> {
		try {
			let returnModel = await this.updateLookup(LookupTypes.PayableCreditCards, payableCreditCardModel);
			await this.refreshPayableCreditCards();
			return Promise.resolve(returnModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}

	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Payment Types
	////////////////////////////////////////////////////////////////////////////////////////////////
	async getPaymentTypesForDropdown(): Promise<IDropdownModel[]> {
		return new Promise<IDropdownModel[]>((resolve) => {
			const paymentTypes = [
				new DropdownModel("Cash", "Cash"),
				new DropdownModel("Check", "Check"),
				new DropdownModel("American Express", "American Express"),
				new DropdownModel("Discover", "Discover"),
				new DropdownModel("Mastercard", "Mastercard"),
				new DropdownModel("Visa", "Visa"),
				new DropdownModel("e-transfer", "e-transfer"),
				new DropdownModel("ACH/EFT", "ACH/EFT"),
				new DropdownModel("Wire Transfer", "Wire Transfer"),
				new DropdownModel("Other", "Other")
			];

			resolve(paymentTypes);
		});
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Pay Period Definitions
	////////////////////////////////////////////////////////////////////////////////////////////////
	async getPayPeriodDefinitions(): Promise<IPayPeriodDefinitionModel[]> {
		return this.getLookup<IPayPeriodDefinitionModel>(LookupTypes.PayPeriodDefinitions, "payPeriodDefinitionId");
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Pay Periods
	////////////////////////////////////////////////////////////////////////////////////////////////
	async getPayPeriods(): Promise<IPayPeriodModel[]> {
		return this.getLookup<IPayPeriodModel>(LookupTypes.PayPeriods, "payPeriodId");
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Referral Sources
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshReferrals(): Promise<IReferralModel[]> {
		return this.refreshLookup<IReferralModel[]>(LookupTypes.Referrals);
	}

	async getReferrals(uuid: string = null): Promise<IReferralModel[]> {
		const referrals = await this.getLookup<IReferralModel>(LookupTypes.Referrals, "referralId", null, true);
		let allReferrals: IReferralModel[] = UtilsService.clone(referrals);
		allReferrals = allReferrals.filter(x => x.isDeleted === false);
		if (uuid) {
			if (allReferrals.findIndex(x => x.uuid === uuid) < 0) {
				const selectedReferral = referrals.find(x => x.uuid === uuid);
				if (selectedReferral)
					allReferrals.push(UtilsService.clone(selectedReferral));
			}
		}
		allReferrals.sort(sortBy("displayName"));
		return allReferrals;
	}

	async getReferralsForDropdown(uuid: string = null): Promise<IDropdownModel[]> {
		return (await this.getReferrals(uuid))
			.map(x => new DropdownModel(x.uuid, x.displayName));
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// ROI Expense Types
	////////////////////////////////////////////////////////////////////////////////////////////////
	async getROIExpenseTypesForDropdown(): Promise<IDropdownModel[]> {
		return new Promise<IDropdownModel[]>((res) => {
			const returnModel = [
				new DropdownModel('Breakfast', 'Breakfast'),
				new DropdownModel('Lunch', 'Lunch'),
				new DropdownModel('Dinner', 'Dinner'),
				new DropdownModel('Gift', 'Gift')
			]

			res(returnModel);
		})
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Teams
	////////////////////////////////////////////////////////////////////////////////////////////////
	refreshTeams(): Promise<ITeamModel[]> {
		return this.refreshLookup<ITeamModel[]>(LookupTypes.Teams);
	}

	async getTeams(teamId?: number, includeInactive?: boolean): Promise<ITeamModel[]> {
		return this.getLookup<ITeamModel>(LookupTypes.Teams, "teamId", teamId, includeInactive);
	}

	async getTeamsForDropdown(teamId?: number): Promise<IDropdownModel[]> {
		return (await this.getLookup<ITeamModel>(LookupTypes.Teams, "teamId", teamId))
			.map(x => new DropdownModel(x.teamId, x.teamName));
	}

	updateTeam(teamModel: ITeamModel): Promise<ITeamModel> {
		return this.updateLookup(LookupTypes.Teams, teamModel);
	}
}
